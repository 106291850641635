 //用户模块
export default {
    namespaced:true,
    state () {
        return {
            profile:{
                id:'',
                username:'',
                token:'',
                pic:'',
                lastlogin:''
            }
        }
    },
    mutations:{
        //修改用户信息
        setUser(state,payload){
            state.profile=payload
        },
        //修改用户头像
        setUserPic(state,pic){
            state.profile.pic=pic
        }
    }
}