import { createRouter, createWebHashHistory } from 'vue-router' 

//页面按需引入
const Layout=()=>import('@/views/Layout.vue')
const Home=()=>import('@/views/home/index.vue')
const Login = ()=>import('@/views/login/index.vue') 
const Pet=()=>import('@/views/home/pet.vue')
const User=()=>import('@/views/home/user.vue')
const Petadd=()=>import('@/views/home/petadd.vue')
const Petedit=()=>import('@/views/home/petedit.vue')
const Album=()=>import('@/views/home/album.vue')
const Petall=()=>import('@/views/home/petall.vue')
const Advertise=()=>import('@/views/home/advertise.vue')

const routes = [
  //一级路由
  {
    path:'/',
    component:Layout,
    children:[
      { path:'/',component:Home },
      { path:'/pet/:id',component:Pet,name:"pet" },
      { path: '/user/:id', component: User,name:"user" },
      { path: '/petadd', component: Petadd,name:"petadd" },
      { path: '/petedit/:id', component: Petedit,name:"petedit" },
      { path: '/album/:id', component: Album,name:"album" },
      { path: '/petall', component: Petall,name:"petall" },
      { path: '/advertise/:name', component: Advertise,name:"advertise" }
    ]
  },
  { path:'/login',component:Login }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  //每次切换时都滚动到页面顶部
  scrollBehavior(){
    return {left:0,top:0}
  }
})

export default router
