//定义中文语言包
export default {
    album:{
        title:"毛孩相册",
        title_up:"图片上传",
        desc_up:"一次可上传4张图片",
        title_edit:"相册编辑",
        desc_edit:"点击图片进行操作",
        btn1:"设为头像",
        btn2:"删除图片",
        btn3:"取消操作",
        warn1:"添加失败:",
        warn2:"发生错误:",
        warn3:"获取oss token失败",
        warn4:"图片没找到",
        warn5:"已经是头像",
        warn6:"头像更新成功",
        warn7:"头像更新失败，请稍后尝试",
        warn8:"头像不能删除",
        warn9:"图片已删除",
        warn10:"图片删除失败，请稍后尝试",
        warn11:"获取失败"
    },
    index:{
        title:"毛孩星球纪念馆",
        title_h2:"你来到我身边,永远住在我心里",
        desc:"毛孩星球，毛孩子的网上纪念馆。毛孩子可以选择一颗星球入住，在里面存放毛孩的相册，摆放小礼物，记录生活点滴。想它的时候，可以来此祭奠、留念。",
        btn1:"毛孩入住",
        btn2:"查看所有",
        btn3:"留言建议",
        btn4:"登录注册",
        btn5:"我的页面",
        adv_label:"建议",   
        adv_placeholder:"请输入建议/合作意向",  
        adv_desc:"请留下建议或合作意向,相关回复会发送到您的邮箱",  
        adv_ok:"确认",
        adv_esc:"取消",
        warn1:"请先登录",
        warn2:"建议不能为空",
        warn3:"建议最多200字",
        warn4:"留言发送成功",
        warn5:"留言失败，请稍后尝试",
        warn6:"获取失败",
        hstr1:"公告"
    },
    pet:{
        hstr1:"住在",
        hstr2:"它的主人:",
        btn1:"留言",
        btn2:"赠礼",
        btn3:"相册编辑",
        btn4:"信息修改",
        hstr3:"它的邻居",
        hstr3_1:"和",
        hstr4:"与",
        hstr5:"等小伙伴住在同一个星球",
        hstr5_1:"查看全部",
        hstr6:"这个礼物由 ",
        hstr7:"赠送于",
        label1:"留言",
        label2:"请输入留言",
        btn5:"发送",
        btn6:"取消",
        hstr8 :"点击选择礼物",
        btn7:"赠送",
        hstr9:"请用支付宝扫描二维码支付:",
        warn1:"留言不能为空",
        warn2:"留言最多200字",
        warn3:"请先选择礼物",
        warn4:"赠礼发送错误，请稍后尝试",
        warn5:"购买礼物成功",
        pstr1:"是一只可爱的",
        pstr2:"品种是",
        pstr3:"现在居住在",
        pstr4:"籍贯",
        pstr5:"生于",
        pstr6:"去世于",
        pstr7:"于",
        pstr8:"入住毛孩星球.",
    },
    petadd:{
        hstr1:"毛孩入住",
        hstr2:"头像上传",
        hstr3:"选择星球",
        hstr4:"星球",
        hstr5:"点击选择星球",
        hstr6:"填写信息",
        hstr7:"名字",
        hstr8:"请输入名字",
        hstr9:"性别:",
        hstr10:"身份:",
        hstr11:"选择毛孩身份",
        hstr12:"品种:",
        hstr13:"选择毛孩品种",
        hstr14:"生日:",
        hstr15:"点击选择生日",
        hstr16:"忌辰:",
        hstr17:"点击选择忌辰",
        hstr18:"籍贯:",
        hstr19:"请输入籍贯",
        hstr20:"入住",
        hstr21:"选择日期",
        warn1:"其它",
        warn2:"请填写毛孩名字",
        warn3:"毛孩名字请不要超过20个字",
        warn4:"请上传毛孩头像",
        warn5:"请选择星球"
    },
    petall:{
        hstr1:"查看 & 搜索 毛孩子",
        hstr2:"请输入毛孩名字"
    },
    petedit:{
        hstr1:"毛孩信息编辑",
        hstr2:"提交",
        warn1:"修改成功",
        warn2:"修改失败，请稍后尝试",
        warn3:"只有主人才能编辑"
    },
    user:{
        hstr1:"Ta的毛孩:",
        hstr2:"更新头像",
        hstr3:"毛孩入住",
        hstr4:"用户注销",
        hstr5:"密码修改",
        hstr6:"输入密码",
        hstr7:"再次输入",
        hstr8:"修改",
        hstr9:"取消",
        hstr10:"不少与8个字符",
        warn1:"请点击并选择图片",
        warn2:"头像更新成功",
        warn3:"头像更新失败，请稍后尝试",
        warn4:"密码不能为空",
        warn5:"密码至少8个字符",
        warn6:"请再输入一遍密码",
        warn7:"密码两次输入不一致",
        warn8:"修改成功",
        warn9:"密码修改失败，请稍后尝试"
    },
    login:{
        hstr1:"用户:",
        hstr2:"请输入用户名",
        hstr3:"密码:",
        hstr4:"请输入密码",
        hstr5:"注册",
        hstr6:"登录",
        hstr7:"返回",
        hstr8:"请再输入一遍",
        hstr9:"邮箱",
        hstr10:"请输入邮箱",
        warn1:"用户名或密码错误",
        warn2:"登录失败",
        warn3:"已有同名用户",
        warn4:"请确认信息正确填写",
        warn5:"密码不能为空",
        warn6:"密码长度8-16,必须有字母和数字",
        warn7:"请再输入一遍密码",
        warn8:"密码两次输入不一致",
        warn9:"请输入邮箱",
        warn10:"请输入正确的邮箱"
    },
    other:{
        dog:"狗狗",
        other:"其它"
    },
    seo:{
        title:"毛孩星球宠物纪念馆",
        title_pet:"的纪念馆",
        key:"宠物,纪念馆 ",
        description:"毛孩星球，毛孩子的网上纪念馆。毛孩子可以选择一颗星球入住，在里面存放毛孩的相册，摆放小礼物，记录生活点滴。想它的时候，可以来此祭奠、留念。"
    }
}