//定义英文语言包
export default {
    album:{
        title:"Pet Album",
        title_up:"Image upload",
        desc_up:"Up to 4 images can be uploaded at once",
        title_edit:"Album edit",
        desc_edit:"Click on the image for operation",
        btn1:"Set as cover",
        btn2:"Del pic",
        btn3:"Cancel",
        warn1:"Add failed:",
        warn2:"Error occurred:",
        warn3:"Failed to obtain token",
        warn4:"Image not found",
        warn5:"Already been Cover",
        warn6:"Cover updated successfully",
        warn7:"Cover update failed, please try again later",
        warn8:"Cover cannot be deleted",
        warn9:"Image deleted",
        warn10:"Image deletion failed, please try again later",
        warn11:"Acquisition failed"
    },
    index:{
        title:"Pet Memorial Hall",
        title_h2:"You will always live in my heart until the end of the world",
        desc:`Pet Star, pet's online memorial hall. Pet can choose a star to move in, store it's photo album inside, place small gifts, and record every little detail of life. When you think of it, you can come here`,
        btn1:"Join in",
        btn2:"View all",
        btn3:"Leave word",
        btn4:"Login",
        btn5:"My space",
        adv_label:"SUG:",  
        adv_placeholder:"Please input suggestions or cooperation intentions",  
        adv_desc:'The relevant reply will be sent to your email.',  
        adv_ok:"OK",
        adv_esc:"ESC",
        warn1:"please log in first",
        warn2:"Suggestion cannot be empty",    
        warn3:"Do not exceed 200 words",
        warn4:"Message sent successfully",
        warn5:"Message sent failed, please try again later",
        warn6:"Acquisition failed",
        hstr1:"Notice"
    },
    pet:{
        hstr1:"live in",
        hstr2:"It's master:",
        btn1:"Leave a word",
        btn2:"Give gifts",
        btn3:"Album editing",
        btn4:"Information edit",
        hstr3:"It's neighbor",
        hstr3_1:"live with",
        hstr4:" and ",
        hstr5:"live in the same star",
        hstr5_1:"View all",
        hstr6:"This gift is from",
        hstr7:"Presented in",
        label1:"MSG",
        label2:"Input the message",
        btn5:"Sent",
        btn6:"Cancel",
        hstr8 :"Click to select gift",
        btn7:"Sent",        
        hstr9:"Please pay with Alipay scanning QR code:",
        warn1:"The message cannot be empty",
        warn2:"Do not exceed 200 words",
        warn3:"Please select a gift first",
        warn4:"Gift sending error, please try again later",
        warn5:"Sent gift Successfully ",
        pstr1:"is a cute ",
        pstr2:"it's breed is ",
        pstr3:"now living in ",
        pstr4:"came from ",
        pstr5:"Born in ",
        pstr6:"Died in ",
        pstr7:"In ",
        pstr8:" it move in Pet Star.",
    },
    petadd:{
        hstr1:"Pet check in",
        hstr2:"Cover upload",
        hstr3:"Select a star",
        hstr4:"Star",
        hstr5:"Click to select a star",
        hstr6:"Input infermation",
        hstr7:"Name:",
        hstr8:"Please input it's name",
        hstr9:"Sex:",
        hstr10:"Species:",
        hstr11:"Select it's species",
        hstr12:"Breed:",
        hstr13:"Select it's breed",
        hstr14:"Birthday:",
        hstr15:"Click to select it's birthday",
        hstr16:"Deathday:",
        hstr17:"Click to select it's deathday",
        hstr18:"Native place:",
        hstr19:"Please input it's native place",
        hstr20:"Check in",
        hstr21:"Select date",
        warn1:"Other",
        warn2:"Please input it's name",
        warn3:"Please do not exceed 20 characters in it's name",
        warn4:"Please upload it's cover",
        warn5:"Please select a star"
    },
    petall:{
        hstr1:"View All & Search",
        hstr2:"Please input pet's name"
    },
    petedit:{
        hstr1:"Pet's info edit",
        hstr2:"Done",
        warn1:"Edit success",
        warn2:"Edit failed,please try later",
        warn3:"Only the master can edit"
    },
    user:{
        hstr1:"Pets:",
        hstr2:"Cover update",
        hstr3:"Pet checkin",
        hstr4:"Logout",
        hstr5:"change pass",
        hstr6:"enter pass",
        hstr7:"enter again",
        hstr8:"OK",
        hstr9:"Cancer",
        hstr10:"At least 8 chars",
        warn1:"Please click to select picture",
        warn2:"Cover update success",
        warn3:"Cover update failed,please try it later",
        warn4:"Password cannot be empty",
        warn5:"Password at least 8 chars",
        warn6:"Please enter the password again",
        warn7:"The password entered twice is inconsistent",
        warn8:"Success",
        warn9:"Password change failed,please try it later"
    },
    login:{
        hstr1:"Username:",
        hstr2:"Please input username",
        hstr3:"Password:",
        hstr4:"Please input password",
        hstr5:"Register",
        hstr6:"Login",
        hstr7:"Cancel",
        hstr8:"Please input password again",
        hstr9:"Email",
        hstr10:"Please input email",
        warn1:"Incorrect username or password",
        warn2:"Login failed",
        warn3:"Already a user with the same name",
        warn4:"Please confirm that the information is filled in correctly",
        warn5:"Password cannot be empty",
        warn6:"Password length should be 8 to 16,must have letters and numbers",
        warn7:"Please enter the password again",
        warn8:"The password entered twice is inconsistent",
        warn9:"Please enter your email",
        warn10:"Please enter the correct email address"
    },
    other:{
        dog:"dog",
        other:"Other breed"
    },
    seo:{
        title:"Pet Star Memorial Hall",
        title_pet:"'s Memorial Hall",
        key:"Pets,Memorial Hall",
        description:"Pet Star, pet's online memorial hall. Pet can choose a star to move in, store it's photo album inside, place small gifts, and record every little detail of life. When you think of it, you can come here."
    }
}