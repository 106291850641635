 //语言模块
export default {
    namespaced:true,
    state () {
        return {
            profile:{
                name:''
            }
        }
    },
    mutations:{
        //修改用户信息
        setLang(state,payload){
            state.profile=payload
        }
    }
}